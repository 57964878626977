.transition-container {
  width: 100%;
  background: #fff;
  margin-left: 15px;
  margin-right: 15px;
  border-radius: 5px;
  padding: 0 !important;
  text-align: left;
}
.item-left-content {
  max-width: 200px;
}
.bg-white {
  background: #fff;
  margin-bottom: 4px;
}
.heading-section {
  /* min-height: 30px; */
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 35px;
  border-bottom: 2px solid #fafafa;
  padding-left: 15px;
  padding-right: 15px;
}

.colorpad-email {
  min-width: 30px;
  min-height: 30px;
  border-radius: 15px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  color: #fff;
}

.d-flex {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.d-flex-icon {
  display: flex;
  /* justify-content: space-around; */
  align-items: end;
  font-size: 12px;
}
.transaction-section {
  display: flex;
  align-items: center;
  background: #eaf1fe;
  width: 70px;
  border-radius: 10px;
  padding: 0px;
  color: #968ff4 !important;
  max-width: 90px;
  padding-left: 5px;
}

.in_progress {
  padding: 2px 10px !important;
  background-color: #fef4e9;
  display: inline-block;
  margin-right: 10px;
  border-radius: 10px;
  text-transform: capitalize;
  font-size: 12px;
  position: relative;
  color: #f5e617 !important ;
  animation: blinker 1s linear infinite;
}

.completed {
  padding: 2px 10px !important;
  background-color: #e8f9ee !important;
  display: inline-block;
  border-radius: 10px;
  margin-right: 10px;
  text-transform: capitalize;
  font-size: 12px;
  position: relative;
  color: #4bb543 !important;
}

.failed {
  padding: 2px 10px !important;
  background-color: #fdeaee;
  display: inline-block;
  border-radius: 10px;
  margin-right: 10px;
  font-size: 12px;
  text-transform: capitalize;
  position: relative;
  color: red !important;
}

.scheduled {
  padding: 2px 10px !important;
  background-color: #1c4cce;
  display: inline-block;
  /* margin-right: 10px; */
  border-radius: 10px;
  text-transform: capitalize;
  font-size: 12px;
  position: relative;
  color: #1c4cce !important;
  animation: blinker 1s linear infinite;
}
span.status-icon {
  top: -7px;
  position: absolute;
  right: -10px;
  /* color: #4bb543;
    /* color: #4bb543; */
}

.completed-text {
  color: #4bb543 !important;
}
.meeting-name-section {
  width: 25%;
}
.email-section {
  width: 22%;
  text-align: left;
}
.meeting-date-section {
  width: 11%;
  text-align: left;
}
.meeting-time-section {
  width: 9%;
  text-align: left;
}
.transcript-time-section {
  width: 10%;
  text-align: left;
}
.status-heading-section {
  width: 10%;
  text-align: left;
}
.meeting-name {
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  margin-top: 0;
  justify-content: space-between;
}
.list-section {
  padding: 10px 10px !important;
  border-bottom: 2px solid #fafafa !important;
}
.right-align {
  text-align: right;
}
.ml-5 {
  margin-left: 5px;
}
.main-wrapper {
  padding-bottom: 0px !important;
}

h6 {
  font-size: 11px;
  color: #6b6868;
}

.pointer {
  cursor: pointer !important;
}
.transcriptIcon {
  width: 10px !important;
}

.fullWidth {
  width: 100% !important;
}
