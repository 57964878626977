.upload-section-container{
    width: 100%;
    background: #fff;
    margin-left: 15px;
    margin-right: 15px;
    border-radius: 5px;
    padding:0 !important;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
    position: relative;
}
.uploadSection{
    border: 1px solid #000;
    border-color: rgba(129,148,245) !important;
    border-width: 2px !important;
}
.file-upload-section{
    text-align: -webkit-center !important;
    text-align: center;
}

.upload-list-section{
    margin-top: 10px;
}
.upload-title{
    display: flex;
    flex-direction: column;
}
.transaction-section-upload{
    display: flex;
    align-items: center;
    background: #a1bfd63d;
    /* width: 75px; */
    border-radius: 10px;
    padding: 0px;
    color: #196BAB !important;
    /* max-width: 75px; */
    /* padding-left: 5px;*/
        padding: 2px 15px !important; 
}
.inner-upload-title{
    display: flex;
    flex-direction:row;
    align-items: center;
    height: 10px;
}
.ml-50{
    margin-left: 50px;
}

.pointer {
    cursor: pointer !important;
}
.transcriptIcon{
    width: 12px !important;
}
.uploadMeetingDate{
    display: flex;
    align-items: flex-start;
}

.uploadBtnSection{
    background: #0E3C60 !important;
    color: #fff !important;
    padding: 5px 20px !important;
}
.headerrowCssNew{
border-top: 2px solid #becee0;
}
.headerrowCssNew p{
    margin-right: 20px;
    margin: 10px!important;
  }

  .ml0{
    margin-left: 0px!important;
  }